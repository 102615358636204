import React from 'react';
import { useTranslation } from 'react-i18next';
import './_style.scss';

const WorkMain = () => {
  const { t } = useTranslation();
  return (
    <div className="main-section-work">
      <div className="section-wrapper">
        <div className="text-section">
          <h1 className="font-texture">{t('jointheteam')}</h1>
          <p className="grey-text">{t('workwithusCapitalized')}</p>
          <h2>{t('WorkSectionTitle')}</h2>
          <div className="rectangle" />
          <p>{t('WorkSectionDescription')}</p>
        </div>
      </div>
    </div>
  );
};

export default WorkMain;
