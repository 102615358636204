export const jobs = [
  {
    title: 'Backend Developer',
    type: 'Development',
    description:
      'You will be a backend specialist within the development team of a new Multiplayer franchise. The ideal candidate has a deep knowledge of backend services development with RESTful APIs, .NET, C#, NET Core, Azure Cloud…',
    mode: 'Full Time',
    location: 'Hybrid',
    extraInfo: 'https://ctstorage1.blob.core.windows.net/ctbundles/web/jobs/Backend.pdf'
  },
  {
    title: 'Data Manager',
    type: 'Business',
    description:
      'Our Data Manager would be a hybrid profile between Data Engineer and Data Analyst, which could help in implementing events, tags, funnels, etc. to extract data and be able to create panels to represent and analyse it.',
    mode: 'Full Time',
    location: 'Hybrid',
    extraInfo: 'https://ctstorage1.blob.core.windows.net/ctbundles/web/jobs/DataManager.pdf'
  },
  {
    title: 'Full Stack Developer',
    type: 'Development',
    description: 'We are looking for a Full Stack developer to join us as a part of our team.',
    mode: 'Full Time',
    location: 'Hybrid',
    extraInfo: 'https://ctstorage1.blob.core.windows.net/ctbundles/web/jobs/FullStack.pdf'
  },
  {
    title: 'Senior QA',
    type: 'Development',
    description:
      'As QA, you will be responsible for evaluating the quality of the software through manual and automatic tests, where you must find and report errors and failures',
    mode: 'Full Time',
    location: 'Hybrid',
    extraInfo: 'https://ctstorage1.blob.core.windows.net/ctbundles/web/jobs/SeniorQA.pdf'
  },
  {
    title: 'Technical Artist',
    type: 'Development',
    description:
      'The Technical Artist is an expert in their field and holds a wealth of knowledge in dealing with the technical issues around content creation for video games. ',
    mode: 'Full Time',
    location: 'Hybrid',
    extraInfo: 'https://ctstorage1.blob.core.windows.net/ctbundles/web/jobs/TechArt.pdf'
  },
  {
    title: 'Unity Developer',
    type: 'Development',
    description: 'We are looking for a Unity Game Developer to join us as a part of our team.',
    mode: 'Full Time',
    location: 'Hybrid',
    extraInfo: 'https://ctstorage1.blob.core.windows.net/ctbundles/web/jobs/UnityDeveloper.pdf'
  }
];
