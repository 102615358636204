import React from 'react';
import WorkMain from '../../components/Work/WorkMain';
import JobList from '../../components/Work/JobList';

const Work = () => {
  return (
    <>
      <WorkMain />
      <JobList />
    </>
  );
};

export default Work;
