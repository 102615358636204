import React from 'react';
import ReactDOM from 'react-dom/client';
import { Layout } from 'antd';
import './i18n';
import App from './App';
import HeaderComponent from './components/Layout/HeaderComponent';
import FooterComponent from './components/Layout/FooterComponent';

const { Header, Footer, Content } = Layout;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Layout className="App">
      <Header>
        <HeaderComponent />
      </Header>
      <Content>
        <App />
      </Content>
      <Footer>
        <FooterComponent />
      </Footer>
    </Layout>
  </React.StrictMode>
);
