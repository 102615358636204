export const en = {
  workwithus: 'Work with us',
  cybertitans: 'CyberTitans',
  joinus: 'Join Us',
  contactUs: 'Contact Us',
  contactUsLink: 'contact@litlabgames.com',
  privacyNotice: 'Privacy Notice',
  jobPositionTitle: 'Jobs',
  moreInfo: 'More Info',
  makingthebestgames: 'MAKING THE BEST GAMES',
  gameDescription:
    'A multiplayer strategy game with crazy mechanics where you lead your titans against the world. Recruit your titans, position them on the field and send them into the battleground.',
  playforfree: 'PLAY FOR FREE',
  jointheteam: 'Join the Team',
  workwithusCapitalized: 'WORK WITH US',
  WorkSectionTitle: 'Start doing work that matters',
  WorkSectionDescription: 'Team up with LitLab Studio.',
  blockchain: 'Blockchain',
  litlabGames: 'LitLabGames',
  web3solutions: 'Web3Solutions',
  aboutUsText:
    'LitLab Games is the first Esports publisher launching games backed by blockchain technology with a crypto-based economy.',
  cookiePolicy: 'Cookie Policy',
  home: 'Home',
  gotIt: 'Got it',
  cookieConsentTitle: 'Cookies Policy',
  cookieConsent:
    'We collect cookies to analyze our website traffic and performance; we never collect any personal data. Continuing to browse on our website means that you accept our cookies policy.',
  smallLegalsBold: 'LitLab Games SL',
  smallLegalBoldTwo: ' "Creación Marketplace NFTs." ',
  smallLegalText:
    ' ha sido beneficiario del Fondo Europeo de Desarrollo Regional cuyo objetivo es mejorar el uso y la calidad de las tecnologías de la información y de las comunicaciones y el acceso a las mismas y gracias a la implantación del proyecto',
  smallLegalTextTwo:
    'Esta acción ha tenido lugar en el periodo de TICCámaras Blockchain 2022. Para ello ha contado con el apoyo del programa TICCámaras de la Cámara de Barcelona.',
  apply: 'Apply',
  legalNotice: 'Legal Notice'
};
