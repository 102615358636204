import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import Logo from '../../../img/logo.svg';
import './_style.scss';

const HeaderComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="app-menu">
      <div className="menu-logo-container">
        <a href="/">
          <img src={Logo} alt="logo" />
        </a>
      </div>
      <Menu mode="horizontal">
        <Menu.Item key="home">
          <a href="/">{t('home')}</a>
        </Menu.Item>
        <Menu.Item key="work">
          <a href="/work">{t('workwithus')}</a>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default HeaderComponent;
