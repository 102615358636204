import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClockCircleOutlined, EnvironmentOutlined } from '@ant-design/icons';
import CardContent from '../../Common/CardContent';
import Cards from '../../Common/Cards';
import { jobs } from '../../../content/jobs';
import './_style.scss';

const JobCards = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="job-offer-wrapper">
        <div className="text-container">
          <div className="rectangle" />
          <h2>{t('jobPositionTitle')}</h2>
        </div>
        <div className="job-cards-container">
          {jobs.map((job, index) => (
            <Cards key={index}>
              <CardContent
                jobType={job.type}
                title={job.title}
                description={job.description}
                URL={job.extraInfo}
                moreInfo={t('moreInfo')}
                timeIcon={<ClockCircleOutlined />}
                mode={job.mode}
                placeIcon={<EnvironmentOutlined />}
                location={job.location}
                buttonText={t('apply')}
              />
            </Cards>
          ))}
        </div>
      </div>
    </>
  );
};

export default JobCards;
