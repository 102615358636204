import React from 'react';
import CBCAT from '../../../img/Camara/cbcat.png';
import UNION from '../../../img/Camara/unioneuropea.png';
import GC from '../../../img/Camara/generalitat.png';
import CAMBRA from '../../../img/Camara/cambra.png';
import TIC from '../../../img/Camara/ticcamaras.png';
import CAMARA from '../../../img/Camara/ccamara.png';
import { useTranslation } from 'react-i18next';
import './_style.scss';

const Camara = () => {
  const { t } = useTranslation();
  return (
    <div className="small-legal">
      <div className="logo-container">
        <div>
          <img className="union" src={UNION} alt="union europea" />
        </div>
        <div>
          <img className="cbcat" src={CBCAT} alt="cbcat" />
        </div>
        <div>
          <img className="generalitat" src={GC} alt="generalitat de catalunya" />
        </div>
        <div>
          <img className="cambra" src={CAMBRA} alt="cambra" />
        </div>
        <div>
          <img className="tic" src={TIC} alt="tic" />
        </div>
        <div>
          <img className="camara" src={CAMARA} alt="camara" />
        </div>
      </div>
      <div className="text-container">
        <p>
          <strong>{t('smallLegalsBold')}</strong>
          <span>{t('smallLegalText')}</span>
          <strong>{t('smallLegalBoldTwo')}</strong>
          <span>{t('smallLegalTextTwo')}</span>
        </p>
      </div>
    </div>
  );
};

export default Camara;
