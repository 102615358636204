import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import './_style.scss';

const HomeMain = () => {
  const { t } = useTranslation();
  return (
    <div className="main-section">
      <div className="section-wrapper">
        <div className="text-section">
          <p className="grey-text">{t('makingthebestgames')}</p>
          <h2>{t('cybertitans')}</h2>
          <div className="rectangle" />
          <p>{t('gameDescription')}</p>
        </div>
        <div className="button-section-wrapper">
          <div className="button-section">
            <Button href="https://www.cybertitans.io/" type="primary" target="_blank">
              {t('playforfree')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeMain;
