import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import { Home, Work } from './pages';
import Cookie from './img/cookie.png';

import './App.less';
import './css/_general_style.scss';
import './css/_app.scss';

const App = () => {
  const { t } = useTranslation();

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/work" element={<Work />} />
      </Routes>
      <CookieConsent location="bottom" buttonText={t('gotIt')}>
        <div className="image-cookie-container">
          <div>
            <img src={Cookie} alt="cookie" />
          </div>
        </div>
        <div className="cookie-text-container">
          <h3>{t('cookieConsentTitle')}</h3>
          <p>{t('cookieConsent')}</p>
        </div>
      </CookieConsent>
    </BrowserRouter>
  );
};

export default App;
