import React from 'react';
import { Button } from 'antd';
import './_style.scss';
import Pills from '../Pills';

const CardContent = ({
  jobType,
  title,
  description,
  URL,
  moreInfo,
  timeIcon,
  mode,
  placeIcon,
  location,
  buttonText
}) => {
  return (
    <div className="card-content">
      <div>
        <Pills>{jobType}</Pills>
        <br />
        <h2>{title}</h2>
        <div className="rectangle" />
        <p>{description}</p>
        <p>
          <a href={URL} target="_blank" rel="noreferrer">
            {moreInfo}
          </a>
        </p>
        <div className="data-container">
          <div className="row">
            {timeIcon}
            <p>{mode}</p>
          </div>
          <div className="row">
            {placeIcon}
            <p>{location}</p>
          </div>
        </div>
      </div>
      <Button href="mailto:roger@litlabgames.com" type="primary" target="_blank">
        {buttonText}
      </Button>
    </div>
  );
};

export default CardContent;
