import React from 'react';
import JobCards from '../JobCards';
import './_style.scss';

const JobList = () => {
  return (
    <div className="work-with-us">
      <div className="job-offer">
        <JobCards />
      </div>
    </div>
  );
};

export default JobList;
