import React from 'react';
import HomeMain from '../../components/Home/HomeMain';
import AboutUs from '../../components/Home/AboutUs';
import Camara from '../../components/Home/Camara';
import './_style.scss';

const Home = () => {
  return (
    <>
      <HomeMain />
      <AboutUs />
      <Camara />
    </>
  );
};

export default Home;
