import React from 'react';
import { useTranslation } from 'react-i18next';
import { MinusOutlined } from '@ant-design/icons';
import Pills from '../../Common/Pills';
import './_style.scss';

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <div className="about-us-landing">
      <div className="title-container">
        <div className="pills-container">
          <Pills>#{t('blockchain')}</Pills>
          <Pills>#{t('litlabGames')}</Pills>
          <Pills>#{t('web3solutions')}</Pills>
        </div>
        <h1 className="font-texture">{t('contactUs')}</h1>
        <h2>{t('contactUs')}</h2>
        <div className="rectangle" />
        <br />
        <p>{t('aboutUsText')}</p>
        <a
          className="contact-mail"
          href="mailto:contact@litlabgames.com"
          target="_blank"
          rel="noreferrer">
          {t('contactUsLink')}
        </a>
      </div>
      <div className="arrow-container">
        <p>
          <MinusOutlined /> {t('contactUs')}
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
