import React from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '../../../img/logo.svg';
import './_style.scss';

const FooterComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="app-footer">
      <div className="footer-image-container">
        <img src={Logo} alt="logo" />
      </div>
      <div className="col-wrapper">
        <div className="col">
          <a href="http://www.cybertitans.io" target="_blank" rel="noreferrer">
            {t('cybertitans')}
          </a>
          <a href="/work">{t('joinus')}</a>
        </div>
        <div className="col">
          <p>
            <strong>{t('contactUs')}</strong>
          </p>
          <a
            className="contact-mail"
            href="mailto:contact@litlabgames.com"
            target="_blank"
            rel="noreferrer">
            {t('contactUsLink')}
          </a>
        </div>
      </div>
      <div className="legal-container">
        <p>
          <a
            href="https://ctstorage1.blob.core.windows.net/ctbundles/web/legal/litlab-studio/PrivacyNotice.pdf"
            target="_blank"
            rel="noreferrer">
            {t('privacyNotice')}.{' '}
          </a>
          <a
            href="https://ctstorage1.blob.core.windows.net/ctbundles/web/legal/litlab-studio/CookiePolicy.pdf"
            target="_blank"
            rel="noreferrer">
            {t('cookiePolicy')}.{' '}
          </a>
          <a
            href="https://ctstorage1.blob.core.windows.net/ctbundles/web/legal/litlab-studio/LegalNotice.pdf"
            target="_blank"
            rel="noreferrer">
            {t('legalNotice')}.
          </a>
        </p>
        <p>2023</p>
      </div>
    </div>
  );
};

export default FooterComponent;
